import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import s1 from '../images/csr/s1.jpg'
import s2 from '../images/senehasa.png'
import s3 from '../images/csr/s3.jpg'
import s4 from '../images/csr/s4.jpg'
import s5 from '../images/csr/s5.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import BgImage from '../images/bg/solutions-page-banner.jpg'



const ServicesPage = () => {
    return (
        <Layout pageTitle="PragICTS | Corporate Social Responsibility">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>
                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>Corporate Social <span><br></br> Responsibility</span></h2>
                                
                                
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>

                    <section data-scrollax-parent="true" id="sec1">
                        <div class="section-subtitle left-pos"  data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>CSR</div>
                        <div class="container">
                       
                            <div class="fl-wrap post-container">
                                <div class="row">
                                    <div class="col-md-12">
                                       
                                        <div class="post fl-wrap fw-post">
                                            <h2><a href="blog-single.html"><span>Corporate Social Responsibility</span></a></h2>
                                            
                                          
                                            
                                            <div class="blog-text fl-wrap">
                                                <div class="clearfix"></div>
                                                
                                                <p>Business is not about or cannot be about just earnings. It is also about humanity, human values, and providing a cohesive platform for creating and molding great human beings with great potential for progress and outcomes.
Our CRS initiatives are in line with these objectives to contribute in our own way and capacity.</p>
                                                
<h3><a href="blog-single.html" >Online Presence Initiative for non-profit organizations <strong></strong></a></h3>
                                                <p>At present, we execute (02) projects a year, where we help non-profit organizations to establish an effective online presence (primarily their website) to help them with their visibility and reach to serve better.
                                                </p>
                                                <p>The software development work for the website is done free of charge. Any discerning non-profit organization can apply to this initiative by writing to <a href='mailto:csr@pragicts.com' target="_blank">csr@pragicts.com</a></p>

                                               
                                            </div>
                                        </div>
                                                                         
                                     
                                      
                                       
                                    
                                    </div>
                                    <div class="col-md-12">
                                        <div class="details-wrap fl-wrap">
                                            
                                           
                                           
                                            <div class="clearfix"></div>
                                            <h4>Projects executed under this initiative are as follows</h4>

                                           
                                            <p>
                                            <img src={s2} ></img><br></br>
                                            Senehasa Foundation (Senehasa Education Resource Research and Information Centre (SERRIC))
                                            </p>
                                            <p>
                                            Senehasa Education Resource Research and Information Centre (SERRIC), functioning under the Ministry of Defence and Ranaviru Seva Authority, aims to provide essential services to children with special needs belonging to families of the Tri Forces, Police, and the Civil Defence Force, as well as families from the civil society with the purpose of increasing their quality of life.
                                            </p>
                                            <p>PragICTS helped the Senehasa Foundation with its new web presence (website and social platforms), branding (new logo design), and corporate video to help them serve better with greater online visibility and reach. This was executed at the request and facilitation of Mrs. Soniya Kottegoda, Chief Supervision Officer Senehasa Foundation, and Deputy Chairperson Ranaviru Seva Authority.</p>

                                            
                                        </div>
                                        <div class="fl-wrap vertical-gallery">
                                               
                                               <div class="box-item">
                                                   <img src={s1} alt=""/>
                                                   <a href={s1} class="fet_pr-carousel-box-media-zoom   image-popup"><i class="fal fa-search"></i></a>
                                               </div>
                                                                       
                                           </div>

                                        <div class="pr-list fl-wrap">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <ul>
                                                        <li><span>Date :</span> October 2020  </li>
                                                        <li><span>Client :</span>  Senehasa.care </li>
                                                        <li><span>Website :</span> <a href="http://senehasa.care/" target="_blank">www.senehasa.care</a> </li>
                                                        <li><span>Location : </span>  <a href="https://www.google.com/maps/search/SERRIC,+Matha+Road,+Manning+Town,+Colombo/@6.9059243,79.8787778,15z/data=!3m1!4b1" target="_blank"> SERRIC, Colombo  </a></li>
                                                    </ul>
                                                </div>
                                               
                                            </div>
                                        </div>
                                                                 
                                       
                                    </div>
                                 
                               
                                  
                                    <div class="limit-box fl-wrap"></div>
                                </div>
                               
                          
                              
                            </div>
                          
                        </div>

                        
                        <div class="bg-parallax-module" data-position-top="50"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
                        <div class="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                        <div class="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                        <div class="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                        <div class="sec-lines"></div>
                    </section>
                
                
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default ServicesPage
